import { Lock } from '@mui/icons-material';
import { Avatar, Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Form } from 'formik';
import React, { useEffect, useState } from 'react';

import connect from '../../../lib/connect';

interface Props extends GlobalProps {
  goToCodePage: any;
  goBack: any;
  selectSource: any;
  setFieldValue: any;
  values: any;
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[1],
    borderRadius: 2,
    width: 300,
    padding: theme.spacing(1),
  },
  avatar: {
    backgroundColor: theme.palette.secondary.A200,
    height: 70,
    width: 70,
    margin: '0 auto 15px auto',
  },
  icon: {
    width: 36,
    height: 36,
  },
  title: {
    textAlign: 'center',
    color: theme.palette.grey['500'],
  },
  form: {
    marginTop: 17,
    width: '100%',
    minHeight: 40,
  },
  link: {
    display: 'inline',
    color: theme.palette.grey['500'],
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary['500'],
    },
  },
  linkLeft: {
    width: '50%',
    display: 'inline-block',
    textAlign: 'left',
  },
  loadingBtn: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: 0,
  },
  media: {
    width: '100%',
    cursor: 'pointer !important',
  },
}));

const { ENV } = process.env;
const AuthAppQR = (props: Props): any => {
  const { api } = props;
  const classes: any = useStyles();

  const [qr, setQr]: any = useState({});

  const getQR = async (): Promise<void> => {
    const { email, phone, securityCodeSource } = props.values;

    props.isLoading(true);
    try {
      const res = await api.service('v1/auth/request-token').create({
        host: ENV === 'development' ? `${email.split('::')[0] || 'admin'}.kentro.cc` : location.host,
        email: ENV === 'development' && email.split('::')[1] ? email.split('::')[1] : email,
        source: securityCodeSource,
        phone,
      });
      setQr(res);
    } catch (err: any) {
      props.toggleSnack(err.message);
    }
    props.isLoading(false);
  };

  useEffect(() => {
    getQR().catch(err => props.toggleSnack(err.message));
  }, []);

  return (
    <Form className={classes.flexColumn}>
      <Avatar className={classes.avatar}>
        <Lock className={classes.icon} />
      </Avatar>
      <Typography className={classes.title}>2-STEP VERIFICATION</Typography>
      <div className={classes.flexColumn}>
        <br />
        {qr?.qr && (
          <img
            className={classes.media}
            src={qr.qr}
            onClick={() => window.open(qr.uri, '_blank')}
            title="2FA QR Code"
          />
        )}
        <br />
        <Button
          size="small"
          className={classes.loadingBtn}
          variant="contained"
          color="primary"
          onClick={props.goToCodePage('authApp')}
        >
          NEXT
        </Button>
        <Button
          size="small"
          className={classes.loadingBtn}
          variant="contained"
          color="secondary"
          onClick={props.goBack}
        >
          BACK
        </Button>
      </div>
    </Form>
  );
};

export default connect()(AuthAppQR);
