import { Fingerprint } from '@mui/icons-material';
import { Avatar, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';
import { Field, Formik, Form as FormOg } from 'formik';
import { TextField } from 'formik-mui';
import gravatar from 'gravatar';
import React, { useEffect, useState } from 'react';
import * as yup from 'yup';

import dispatchers from './dispatchers';
import connect from '../../../lib/connect';
import { toggleSnack } from '../../../redux/actions';
import LoadingButton from '../../partial/LoadingButton';
import AuthAppQR from '../AuthAppQR';
import EnterPhoneNumber from '../EnterPhoneNumber';
import EnterToken from '../EnterToken';
import Select2FA from '../Select2FA';

// import qs from 'query-string';

interface Props extends GlobalProps {
  submit: any;
}

const Form = FormOg as any;

const { ENV } = process.env;
const useStyles = makeStyles((theme: any) => ({
  loginRoot: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[1],
    borderRadius: 2,
    width: 300,
    padding: theme.spacing(1),
  },
  avatar: {
    backgroundColor: theme.palette.secondary.A200,
    height: 70,
    width: 70,
    margin: '0 auto 15px auto',
  },
  icon: {
    width: 36,
    height: 36,
  },
  title: {
    textAlign: 'center',
    color: theme.palette.grey['500'],
  },
  formField: {
    margin: 0,
    width: '100%',
    minHeight: 40,
    '&:first-child': { marginTop: theme.spacing(1) },
  },
  link: {
    display: 'inline',
    color: theme.palette.grey['500'],
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary['500'],
    },
  },
  loadingBtn: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: 0,
  },
  linkLeft: {
    width: '50%',
    display: 'inline-block',
    textAlign: 'left',
  },
  leftIcon: { marginRight: theme.spacing(1) },
}));

const login = (props: Props): any => {
  const {
    api,
    router,
    redux: { state },
  } = props;
  const classes: any = useStyles();
  const [step2FA, setStep2FA] = useState('');
  const [avatar, setAvatar] = useState('');
  const [userProfile, setUserProfile] = useState({
    enabledPhone2FA: false,
    enabledEmail2FA: false,
    enabledApp2FA: false,
    defaultSource: '',
  });

  // @ts-ignore
  const isGlobalLoginPage = true; // process.browser && location?.hostname?.split('.')?.[0] === 'login';

  const initialValues = () => ({
    phone: '',
    email: '',
    password: '',
    securityCode: '',
    securityCodeSource: '',
  });

  const validationSchema = () => {
    return yup.object().shape({
      email: ENV === 'development' ? yup.string().required() : yup.string().required().email(),
      password: yup.string().required().min(6),
      securityCode: yup.string().required(),
      securityCodeSource: yup.string().required(),
    });
  };

  const validateEmailAndPW = async (values): Promise<void> => {
    const { email, password } = values;
    props.isLoading(true);
    try {
      const res = await api.service('v1/auth/verify-user-credentials').create({
        host: ENV === 'development' ? `${email.split('::')[0] || 'admin'}.kentro.cc` : location.host,
        email: ENV === 'development' && email.split('::')[1] ? email.split('::')[1] : email,
        password,
      });
      setUserProfile(res);
      setStep2FA('select');
    } catch (err: any) {
      props.toggleSnack(err.message);
    }
    props.isLoading(false);
  };

  const requestCode = (source, values, setFieldValue) => async (): Promise<void> => {
    const { email, phone } = values;
    setFieldValue('securityCodeSource', source);

    if (!['email', 'sms'].includes(source)) {
      return setStep2FA('enter-code');
    }

    props.isLoading(true);
    try {
      await api.service('v1/auth/request-token').create({
        host: ENV === 'development' ? `${email.split('::')[0] || 'admin'}.kentro.cc` : location.host,
        email: ENV === 'development' && email.split('::')[1] ? email.split('::')[1] : email,
        source,
        phone,
      });
      setStep2FA('enter-code');
    } catch (err: any) {
      props.toggleSnack(err.message);
    }
    props.isLoading(false);
  };

  const selectSource = (values, setFieldValue) => (source: 'email' | 'sms' | 'authApp') => {
    if (source === 'email' && !userProfile.enabledEmail2FA) {
      return toggleSnack('This authentication method was disabled by user!');
    }
    if (source === 'email' && userProfile.enabledEmail2FA) {
      setStep2FA('enter-code');
      return requestCode(source, values, setFieldValue)();
    }
    if (source === 'sms' && userProfile.enabledPhone2FA) {
      setStep2FA('enter-code');
      return requestCode(source, values, setFieldValue)();
    }
    if (source === 'authApp' && userProfile.enabledApp2FA) {
      setFieldValue('securityCodeSource', source);
      return setStep2FA('enter-code');
    }
    setFieldValue('securityCodeSource', source);
    return setStep2FA('setup');
  };

  const submit = async (values): Promise<void> => {
    const { email, password, securityCode, securityCodeSource } = values;
    props.isLoading(true);

    return props
      .login({
        email: ENV === 'development' && email.split('::')[1] ? email.split('::')[1] : email,
        password,
        securityCode,
        securityCodeSource,
        host: ENV === 'development' ? `${email.split('::')[0] || 'admin'}.kentro.cc` : location.host,
        strategy: 'admin',
      })
      .then(user => {
        // if (user && !user?.tid?.subscription?.plan) {
        //   location.href = '/setup';
        // } else {
        location.href = '/';
        // }
      })
      .catch(err => {
        props.isLoading(false);

        if (err && err.message === 'not authenticated') {
          return props.toggleSnack('Wrong login or password!');
        }
        if (err && err.message) {
          return props.toggleSnack(err.message);
        }

        return props.toggleSnack('Something went wrong, contact support!');
      });
  };

  const getAvatar = (email: string): void => {
    const emailIsValid = (str: string): boolean => {
      return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(str);
    };
    const testImage = (url): Promise<boolean> => {
      return new Promise(resolve => {
        const timeout = 2000;
        const img = new Image();
        let timer;

        img.onerror = img.onabort = () => {
          clearTimeout(timer);
          resolve(false);
        };
        img.onload = () => {
          clearTimeout(timer);
          resolve(true);
        };
        timer = setTimeout(() => {
          // reset .src to invalid URL so it stops previous
          // loading, but doesn't trigger new load
          img.src = '//!!!!/test.jpg';
          resolve(false);
        }, timeout);
        img.src = url;
      });
    };

    if (emailIsValid(email)) {
      const url: string = gravatar.url(email, { s: '80', r: 'pg', d: '404' });
      testImage(url).then(valid => (valid ? setAvatar(url) : setAvatar('')));
    }
  };

  useEffect(() => {
    // @ts-ignore
    if (process.browser && location.hash) {
      try {
        const { accessToken } = JSON.parse(atob(location.hash.slice(1)));
        props
          .login({ accessToken, strategy: 'jwt' })
          .then(user => {
            if (user && !user.tid.subscription.plan) {
              location.href = '/setup';
            } else {
              location.href = '/';
            }
          })
          .catch(err => {
            props.isLoading(false);
            props.toggleSnack(err.message);
          });
      } catch (err: any) {
        props.isLoading(false);
      }
    } else {
      props.isLoading(false);
    }
  }, []);

  // console.log('isGlobalLoginPage', isGlobalLoginPage);
  return (
    <div className={classes.loginRoot}>
      <Formik onSubmit={submit} initialValues={initialValues} validationSchema={validationSchema()}>
        {({ values, isSubmitting, isValid, setFieldValue }: any) => {
          if (step2FA === 'select') {
            return (
              <Select2FA
                {...props}
                goBack={() => setStep2FA('')}
                selectSource={selectSource(values, setFieldValue)}
                userProfile={userProfile}
              />
            );
          }
          if (values.securityCodeSource === 'sms' && step2FA === 'setup') {
            return (
              <EnterPhoneNumber
                {...props}
                goToCodePage={source => requestCode(source, values, setFieldValue)}
                goBack={() => setStep2FA('select')}
                setFieldValue={setFieldValue}
              />
            );
          }
          if (values.securityCodeSource === 'authApp' && step2FA === 'setup') {
            return (
              <AuthAppQR
                {...props}
                values={values}
                goToCodePage={source => requestCode(source, values, setFieldValue)}
                goBack={() => setStep2FA('select')}
              />
            );
          }
          if (step2FA === 'enter-code') {
            return <EnterToken {...props} goBack={() => setStep2FA('select')} isValid={isValid} />;
          }

          return (
            <Form className={classes.flexColumn}>
              {getAvatar(values.email)}
              {!avatar ? (
                <Avatar className={classes.avatar}>
                  <Fingerprint className={classes.icon} />
                </Avatar>
              ) : (
                <Avatar className={classes.avatar} alt={values.email} src={avatar} />
              )}
              <Typography className={classes.title}>AUTHENTICATION</Typography>
              <div>
                <Field
                  component={TextField}
                  className={classes.formField}
                  label="email"
                  name="email"
                  type={ENV === 'development' ? 'text' : 'email'}
                  margin="none"
                  variant="outlined"
                />
                <Field
                  component={TextField}
                  className={classes.formField}
                  label="password"
                  name="password"
                  type="password"
                  margin="none"
                  variant="outlined"
                />
              </div>
              <div className={classNames(classes.flex, classes.spaceBetween)}>
                <Typography className={classes.link} onClick={() => router.push('/reset-password')}>
                  forgot password?
                </Typography>
              </div>
              <LoadingButton
                className={classes.loadingBtn}
                variant="contained"
                color="primary"
                width="100%"
                onClick={() => validateEmailAndPW(values)}
                disabled={isSubmitting || state.loading || !values.email || !values.password}
                loading={isSubmitting || state.loading}
                label="LOG IN"
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export default connect({ dispatchers })(login);
