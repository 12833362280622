import Head from 'next/head';
import React from 'react';

import Snackbar from '../components/layout/Snackbar';
import Login from '../components/login/Login';
import Loader from '../components/partial/Loader';
import connect from '../lib/connect-page';

interface Props extends GlobalProps {}

const styles = (theme): any => ({
  loginWrapper: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    height: '100%',
    minWidth: '100vw',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary['200'],
  },
});

const Page = (props: Props) => {
  const { classes, ...strippedProps } = props;
  const {
    redux: { state },
  } = strippedProps;

  return (
    <div className={classes.loginWrapper}>
      <Head>
        <title key="title">Login | Kentro.io</title>
      </Head>
      <Login />
      <Snackbar />
      {state.loading && <Loader />}
    </div>
  );
};

export default connect({ styles })(Page);
