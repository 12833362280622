import { Lock } from '@mui/icons-material';
import { Avatar, Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Field, Form } from 'formik';
import { TextField } from 'formik-mui';
import React from 'react';

import connect from '../../../lib/connect';
import LoadingButton from '../../partial/LoadingButton';

interface Props extends GlobalProps {
  requestCode: any;
  goBack: any;
  isValid: any;
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[1],
    borderRadius: 2,
    width: 300,
    padding: theme.spacing(1),
  },
  avatar: {
    backgroundColor: theme.palette.secondary.A200,
    height: 70,
    width: 70,
    margin: '0 auto 15px auto',
  },
  icon: {
    width: 36,
    height: 36,
  },
  title: {
    textAlign: 'center',
    color: theme.palette.grey['500'],
  },
  form: {
    marginTop: 17,
    width: '100%',
    minHeight: 40,
  },
  link: {
    display: 'inline',
    color: theme.palette.grey['500'],
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary['500'],
    },
  },
  linkLeft: {
    width: '50%',
    display: 'inline-block',
    textAlign: 'left',
  },
  loadingBtn: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: 0,
  },
}));

const EnterToken = (props: Props): any => {
  const {
    redux: { state },
  } = props;
  const classes: any = useStyles();

  return (
    <Form className={classes.flexColumn}>
      <Avatar className={classes.avatar}>
        <Lock className={classes.icon} />
      </Avatar>
      <Typography className={classes.title}>2-STEP VERIFICATION</Typography>
      <div className={classes.flexColumn}>
        <Field
          component={TextField}
          className={classes.form}
          label="security code"
          name="securityCode"
          type="number"
          margin="none"
          variant="outlined"
        />
        <LoadingButton
          className={classes.loadingBtn}
          variant="contained"
          color="primary"
          type="submit"
          width="100%"
          disabled={!props.isValid || state.loading}
          loading={state.loading}
          label="LOG IN"
        />
        <Button
          size="small"
          className={classes.loadingBtn}
          variant="contained"
          color="secondary"
          onClick={props.goBack}
        >
          BACK
        </Button>
      </div>
    </Form>
  );
};

export default connect()(EnterToken);
