import { Lock } from '@mui/icons-material';
import { Avatar, Button, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { Form } from 'formik';
import React from 'react';

import connect from '../../../lib/connect';
import MuiPhoneNumber from '../../partial/MuiPhoneField';

interface Props extends GlobalProps {
  goToCodePage: any;
  goBack: any;
  selectSource: any;
  setFieldValue: any;
}

const useStyles = makeStyles((theme: any) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.default,
    boxShadow: theme.shadows[1],
    borderRadius: 2,
    width: 300,
    padding: theme.spacing(1),
  },
  avatar: {
    backgroundColor: theme.palette.secondary.A200,
    height: 70,
    width: 70,
    margin: '0 auto 15px auto',
  },
  icon: {
    width: 36,
    height: 36,
  },
  title: {
    textAlign: 'center',
    color: theme.palette.grey['500'],
  },
  form: {
    marginTop: 17,
    width: '100%',
    minHeight: 40,
  },
  link: {
    display: 'inline',
    color: theme.palette.grey['500'],
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary['500'],
    },
  },
  linkLeft: {
    width: '50%',
    display: 'inline-block',
    textAlign: 'left',
  },
  loadingBtn: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: 0,
  },
}));

const EnterPhoneNumber = (props: Props): any => {
  const classes: any = useStyles();

  return (
    <Form className={classes.flexColumn}>
      <Avatar className={classes.avatar}>
        <Lock className={classes.icon} />
      </Avatar>
      <Typography className={classes.title}>2-STEP VERIFICATION</Typography>
      <div className={classes.flexColumn}>
        <MuiPhoneNumber
          className={classes.form}
          defaultCountry={'us'}
          onChange={phone => props.setFieldValue('phone', phone)}
          label="phone"
          name="phone"
          type="tel"
          margin="none"
          variant="outlined"
        />
        <Button
          size="small"
          className={classes.loadingBtn}
          variant="contained"
          color="primary"
          onClick={props.goToCodePage('sms')}
        >
          NEXT
        </Button>
        <Button
          size="small"
          className={classes.loadingBtn}
          variant="contained"
          color="secondary"
          onClick={props.goBack}
        >
          BACK
        </Button>
      </div>
    </Form>
  );
};

export default connect()(EnterPhoneNumber);
